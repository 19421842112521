html, #root, .App {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
  padding-top: 7%;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.ipDetail {
  text-align: left;
margin-top: 2%;

}

.ipDetail.location {
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px; /* Rounded corners */
  padding: 15px; /* Padding inside the border */
  margin: 3%; /* Margin for spacing around the element */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for depth */
  width: 30%; /* Fixed width */
}

.ipDetail.location table {
  width: 100%; /* Adjust the width as needed */
}

.ipDetail.location td {
  padding: 5px 10px; /* Adjust the padding for spacing */
}



.ipDisplayContainer {
    display: flex;
    align-items: center;  /* Center items vertically */
    gap: 16px;  /* Spacing between elements */
  }

  .ipContainer {
    display: flex;
    flex-direction: column; /* stacks children vertically */
    align-items: center;  /* Center items horizontally */
    justify-content: center;  /* Center items vertically */
    margin-top: 3%;
 }
  

h4 {
    margin: auto;
    padding-top: 3%;
}

.header {
  font-family: 'Handjet';
  background-color: #0072C6; /* Teal color */
  padding: 1%;
  color: white;
  letter-spacing: 18px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%; /* Set width to 100% of the viewport width */
  position: fixed; /* Adjust as per layout requirements */
  margin-left: -50vw;
  margin-right: -50vw;
top: 0 ;
right: 0;
left: 0;
margin: auto;
  z-index: 10;
}

.header > h1 {
  font-size: 60px;
}

.container {
  margin: 0 auto; /* Center the container */
  padding: 0; /* Remove default padding */
  max-width: 100%; /* Set maximum width */
  box-sizing: border-box; /* Include padding and border in the width */
}

.description{
    font-family: 'Courier New', Courier, monospace;
    letter-spacing: 0px !important;
    margin: auto;
    color: black;
    margin-top: 3%;
   font-size: 1.2rem;
}

.ipDisplayed {

    background-color: #CBCFC9;
    text-align: center;
}

.location {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0.1em;
    font-size: small;
    color:#535453;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
  cursor: pointer;
  color: #65655E;
  position: relative;

}

.material-symbols-outlined:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -1.9em;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    color: black;
    padding: 0.5em;
    border-radius: 5px;
    font-size: 0.55em;
    pointer-events: none;
    z-index: 10;
    font-family: 'Courier New', Courier, monospace !important;
  }

#yourIP {
    color:#0D2C54;
    letter-spacing: 2px;
    margin-bottom: 2%;
}

.ipAddress {
  margin: 0;  /* Remove any default margins */
  font-size: 3rem;
  color: #23231A;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .ipAddress {
      font-size: 2rem;
  }
}


.light-mode {
    background-color: #fff;
    color: #333;
}

.body {
    background-color: #f4f4f4;
    color: #fff;
    height: 100vh
}

button {
    padding: 10px 20px;
    cursor: pointer;
}



/* New spinner class */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-top-color: #70C3FF;
    animation: spin 1s ease-in-out infinite;
    margin: auto;
    display: block;
    margin-bottom: 2%;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }
  

  
  