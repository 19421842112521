/*
**** HTML TEMPLATE *****
    <>
        <div className="blog-container">
          <div className="text-container">
            <h1 className="blog-title">Blog Title</h1>
            <p className="blog-paragraph">
              This is some text in the first paragraph of the blog post.
            </p>
            <p className="blog-paragraph">
              This is some text in the second paragraph of the blog post.
            </p>
                Add more paragraphs as needed 
            </div>
            </div>
          </>
***********************
*/

.blog-container {
    max-width: 90%; /* Adjust for desired width */
    margin: 0 auto; /* Center the container */
    padding: 20px;
    box-sizing: border-box;
  }
  
  .text-container {
    background-color: #f8f8f8; /* Light grey background, change as needed */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    text-align: left;
  }

  .code-wrapper {
    display: inline-block; /* Allows the wrapper to fit the width of its content */
    background-color: #f5f5f5; /* Light grey background */
    border: 1px solid #ddd; /* Light border for the container */
    border-radius: 5px; /* Rounded corners */
    padding: 10px; /* Padding inside the container */
    margin: 2%;
  }
  
  .code-wrapper pre {
    margin: 0; /* Remove default margin */
    white-space: pre; /* Preserve whitespaces and line breaks */
  }
  
  .code-wrapper code {
    font-family: 'Courier New', monospace; /* Monospaced font for code */
    color: #333; /* Dark color for text */
    font-size: 16px; /* Adjust font size as needed */
  }
  
  
  .blog-title {
    margin-top: 0;
    color: #333; /* Dark text color for the title */
    font-size: 24px; /* Adjust title size */
  }
  
  .blog-paragraph {
    color: #666; /* Lighter text color for content */
    line-height: 1.6; /* Line height for readability */
    margin-bottom: 15px; /* Spacing between paragraphs */
  }
  
  /* Responsive design for mobile devices */
  @media screen and (max-width: 600px) {
    .blog-container {
      padding: 10px;
    }
  
    .text-container {
      padding: 15px;
    }
  
    .blog-title {
      font-size: 20px;
    }
  }
  