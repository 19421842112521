.about-container {
margin-top: 2%;
margin-left: 8%;
right: 5;

}

.aboutTitle {
    color: black;

}